<template>
  <div class="container">
    <div class="box">
      <div class="top">
        <img class="lost-img" alt="引导图片" src="../assets/lost-img-min.png" />
        <div class="text-box">
          <img class="tip-text" alt="哎呀" src="../assets/tip-text-min.png" />
          <div class="explain-text">你的页面丢失啦......<br class="br" />去首页看看吧～</div>
        </div>
      </div>
      <div class="bottom">
        <Button class="button" text="去首页" @click="buttonClick" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Button from '@/components/Button.vue';

let throttleSwitch = true;
export default {
  name: 'Home',
  components: {
    Button,
  },
  methods: {
    buttonClick() {
      // 截流，优化短时间内多次点击按钮
      if (throttleSwitch) {
        throttleSwitch = false;
        const userAgent = navigator && navigator.userAgent;
        // if (/MicroMessenger/gi.test(userAgent)) {
        if (/WeChat\//gi.test(userAgent)) {
          window.location.replace(
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47031447c8352579&redirect_uri=https%3A%2F%2Fchong.qq.com%2Fmobile%2Fh5_charge_new_test.html&response_type=code&scope=snsapi_base&state=qqchongzhi#wechat_redirect',
          );
          // } else if (/MQQBrowser/gi.test(userAgent)) {
        } else if (/QQ\/[0-9]/gi.test(userAgent)) {
          window.location.replace('https://chong.qq.com/mobile/sq/wallet/index.html#/?_vacf=qw');
        } else {
          window.location.replace('https://chong.qq.com/');
        }
        this.$eventReport('charge.404page.skipButton_click', {
          fchannel_id: document && document.referrer,
        });
        setTimeout(() => {
          throttleSwitch = true;
        }, 500);
      }
    },
  },
  mounted() {
    this.$eventReport('charge.404page.pageview_brow', {
      fchannel_id: document && document.referrer,
    });
  },
};
</script>

<style scoped lang="less">
.container {
  overflow: hidden;

  // 安全区域
  box-sizing: border-box;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100vw;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));

  background-color: #21c17a;

  user-select: none;
  > .box {
    display: flex;
    overflow: scroll;

    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    > .top {
      text-align: center;
      > .lost-img {
        margin: 0.94rem 0 0.48rem;
        width: 5.1rem;
        height: 4.16rem;
      }
      > .text-box {
        > .tip-text {
          width: 1.84rem;
        }
        > .explain-text {
          font-size: 0.24rem;

          color: #fff;
        }
      }
    }
    > .bottom {
      display: flex;

      flex-direction: column;
      align-items: center;

      padding: 0.16rem 0 2.16rem;
      min-height: 0.88rem;

      text-align: center;
    }

    @media screen and (orientation: landscape) {
      > .top {
        > .lost-img {
          margin: 1rem 0 1.06rem;
          width: 8.16rem;
          height: 6.36rem;
        }
        > .text-box {
          > .tip-text {
            width: 2.1rem;
            height: 1rem;
          }
          > .explain-text {
            margin-top: 0.22rem;
            font-size: 0.55rem;

            color: #fff;
            > .br {
              display: none;
            }
          }
        }
      }
      > .bottom {
        padding-bottom: 0.58rem;
        min-height: 2.44rem;
      }
    }
  }
}
</style>

<style lang="less">
* {
  -webkit-overflow-scrolling: touch;
}
</style>
