<template>
  <button class="button" @click="click">{{ text }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="less">
.button {
  display: inline-block;

  border: none;
  border-radius: 4px;
  padding: 0;
  width: 4.32rem;
  height: 0.88rem;

  font-size: 0.34rem;
  font-weight: 600;
  line-height: 0.88rem;
  text-align: center;

  color: #21c17a;
  background-color: #fff;

  outline: none;

  transition: opacity 0.2s ease-out;
  &:active {
    opacity: 0.5;
  }
  @media screen and (orientation: landscape) {
    width: 11.98rem;
    height: 2.44rem;

    font-size: 0.74rem;
  }
}
</style>
