<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 视口高度偏移量：100vh - window.innerHeight，适配浏览器vh异常问题
      // https://2heng.xin/2019/09/18/fix-100-vh-in-mobile-browser/
      vhOffset: 0,
    };
  },
  methods: {
    updateVhOffset() {
      const testElement = document.createElement('div');
      testElement.style.cssText = 'position: fixed; top: 0; height: 100vh; pointer-events: none;';
      document.documentElement.insertBefore(testElement, document.documentElement.firstChild);
      const windowHeight = window.innerHeight;
      const vh = testElement.offsetHeight;
      this.vhOffset = vh - windowHeight;
      document.documentElement.style.setProperty(`--vh-offset`, `${this.vhOffset}px`);
      document.documentElement.removeChild(testElement);
    },
  },
  created() {
    window.addEventListener('resize', this.updateVhOffset);
    this.updateVhOffset();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateVhOffset);
  },
};
</script>

<style lang="less">
body {
  margin: 0;
}
</style>

<style scoped lang="less"></style>
