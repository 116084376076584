import Vue from 'vue';
import { Report } from '@tencent/chong-vue-report';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(Report, {
  sid: '500685256', // 必填，统计用的appid
  cid: '500685257', // 如果开启自定义事件，此项目为必填，否则不填
  autoReport: 0, // 是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
  senseHash: 1, // hash锚点是否进入url统计
  senseQuery: 0, // url参数是否进入url统计
  performanceMonitor: 1, // 是否开启性能监控
  ignoreParams: [], // 开启url参数上报时，可忽略部分参数拼接上报
  // openId: () => {
  //   return (Cookie.get('uin') || Cookie.get('p_luin') || Cookie.get('luin') || '').replace(/^o0?/, '');
  // }, // 提供openId的获取方法
  className: 'sq_chong', // 海雀上的一级分类名
  fsource: 'sq_chong', // 用于bi报表数据制作
  expiredMins: 5, // 用于规定本地cookie内eventId过期时间
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to?.meta?.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
